import "@fortawesome/fontawesome-free/css/all.css";
import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";
import VueTheMask from "vue-the-mask";

Vue.use(VueTheMask);

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    themes: {
      light: {
        primary: "#00a3d9",
        secondary: "#F1F5F9",
        accent: "#2b2b2b",
        background: "#F1F5F9",
        blueColor: "#00A3D9",
        lightBlue: "#00BFFF",
        greyColor: "#929292",
        danger: "#FF3F3F"
      },
      dark: {
        primary: "#2B313B",
        secondary: "#171817",
        accent: "#2b2b2b",
        background: "#252525",
      },
    },
  },
});
