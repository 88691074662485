<template>
  <v-app class="app" :style="{background: $vuetify.theme.themes[theme].background}" >
    <v-main class >
      <RouterView />
    </v-main>
    <v-footer v-if="$route.meta?.requiresAuth">
      <v-col class="text-center">
        <project-version />
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import { RouterView } from "vue-router";
import ProjectVersion from "@/components/data/ProjectVersion.vue";

export default {
  name: "App",

  components: {
    RouterView,
    ProjectVersion,
  },

  data: () => ({
    //
  }),

  computed: {
    theme(){
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }
  },
};
</script>

<style>
  .text-danger {
    color: #FF4848 !important;
  }

  .text-blue {
    color: #00BFFF !important;
  }

  .text-green {
    color: #009C22;
  }

  .text-regular {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;

    color: #929292;
  }

  .custom-container {
    width: 90vw !important;
    margin-left: auto;
    margin-right: auto;
  }

  .rounded-16 {
    border-radius: 16px !important;
  }
  .thin-border {
    border: 1px solid rgba(0,0,0,.12);
  }

  .box-p-20 {
    padding: 20px;
  }

  /* @media (max-width: 599px) {
    .inner-container {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  @media (min-width: 600px) and (max-width: 959px) {
  }

  @media (min-width: 960px) and (max-width: 1263px) {
    .inner-container {
      margin-left: 95px;
      margin-right: 95px;
    }
  }

  @media (min-width: 1264px) {
    .inner-container {
      margin-left: 95px;
      margin-right: 95px;
    }
  } */

  .toast-alert {
    top: 80px !important
  }
</style>
