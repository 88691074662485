<template>
  <small>
    Gerir Azul - Versão {{ version }}
  </small>
</template>

<script>
import pkg from '../../../package.json';

export default {
  data() {
    return {
      version: pkg.version,
    }
  },
};
</script>