import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      meta: { requiresAuth: true },
      component: () => import("@/views/DashboardView.vue"),
    },
    {
      path: "/financial-transaction-summary",
      nome: "financial-transaction-summary",
      meta: { requiresAuth: true },
      component: () => import("@/views/FinancialTransactionSummaryView.vue"),
    },
    {
      path: "/branch-offices",
      nome: "branch-offices",
      meta: { requiresAuth: true },
      component: () => import("@/views/BranchOfficesView.vue"),
    },
    {
      path: "/branch-offices/:id",
      nome: "branch-office",
      meta: { requiresAuth: true },
      component: () => import("@/views/BranchOfficeView.vue"),
    },
    {
      path: "/profile",
      nome: "profile",
      meta: { requiresAuth: true },
      component: () => import("@/views/ProfileView.vue"),
    },
    {
      path: "/financial-transactions-summary",
      nome: "financial-transactions-summary",
      meta: { requiresAuth: true },
      component: () => import("@/views/FinancialTransactionsSummaryView.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/LoginView.vue"),
      beforeEnter: (to, from, next) => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");
        const isAuthenticated = token && user;
        if (isAuthenticated) {
          next({
            name: "home",
          });
        } else {
          next();
        }
      },
    },
    {
      path: "/forgot-password",
      name: "forgot-password",
      component: () => import("@/views/ForgotPasswordView.vue"),
    },
    {
      path: "/register",
      nome: "register",
      component: () => import("@/views/RegisterView.vue"),
    },
    {
      path: "/verify-email",
      nome: "verify-email",
      component: () => import("@/views/VerifyEmailView.vue"),
    },
    {
      path: "/confirm-email",
      nome: "confirm-email",
      component: () => import("@/views/ConfirmEmailView.vue"),
    },
    {
      path: "/reset-password",
      name: "reset-password",
      component: () => import("@/views/ResetPasswordView.vue"),
    },
    {
      path: "/logout",
      name: "logout",
      beforeEnter: (to, from, next) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        next({ name: "login" });
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  const user = localStorage.getItem("user");
  const isAuthenticated = token && user;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({
        name: "login",
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
